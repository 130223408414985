<template>
  <div class="lastBox" style="background-color: #f8fcff;">
    <div class="banner">
      <p class="p1">大宗商品</p>
      <p class="p2"></p>
      <p class="p3">
        本公司长期出售：废铁、铜、铝、不锈钢及有色金属和报废车辆拆解料等。
        凭借优质的服务，合理的价格，公司成立10多年已与全国多家企业建立了固定的合作关系。我们坚持"以客户为中心"，诚信服务，取得广大客户的信赖和满意。在同行业和客户中有着较高的信誉！欢迎新老客户的来电洽淡！
      </p>
    </div>
    <div class="aircont">
      <el-card :body-style="{ padding: '20px 20px 0 20px' }" style="width: 1180px;margin: 20px auto;" class="box-card">
        <div class="menu">
          <div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="品名类型:">
                <el-tabs style="padding-left: 47px;" v-model="commodityTypes" @tab-click="
                    handleClick(
                      $event,
                      'commodityTypes',
                      'ParentCommoditytypeId'
                    )
                  ">
                  <el-tab-pane label="全部" name="all"></el-tab-pane>
                  <el-tab-pane v-for="item in commodityTypesList" :key="item.commoditytypeId"
                    :label="item.commoditytype" :name="`${item.commoditytypeId}`"></el-tab-pane>
                </el-tabs>
              </el-form-item>
              <el-form-item label="品名名称:" v-if="commoditiesList.length > 0">
                <el-tabs style="padding-left: 47px;" v-model="commodities" @tab-click="
                    handleClick($event, 'commodities', 'CommoditytypeId')
                  ">
                  <el-tab-pane label="全部" name="1"></el-tab-pane>
                  <el-tab-pane v-for="item in commoditiesList" :key="item.id" :label="item.name" :name="`${item.id}`">
                  </el-tab-pane>
                </el-tabs>
              </el-form-item>
              <el-form-item label="项目状态:">
                <el-tabs style="padding-left: 47px;" v-model="ListCommodityType" @tab-click="
                    handleClick(
                      $event,
                      'listCommodityType',
                      'ListCommodityType'
                    )
                  ">
                  <el-tab-pane label="全部" name="4"></el-tab-pane>
                  <el-tab-pane label="竞标中" name="0"></el-tab-pane>
                  <el-tab-pane label="竞标结束" name="1"></el-tab-pane>
                  <!-- <el-tab-pane label="历史记录" name="2"></el-tab-pane> -->
                  <el-tab-pane label="未开始" name="3"></el-tab-pane>
                </el-tabs>
                <el-form class="attribute" style="width: 300px;position: absolute;right: 0;top: -2px;">
                  <el-input v-model="FindKeyWords" placeholder="请输入关键词" @keyup.enter.native="onUpSearch" clearable
                    @keypress="
                      event => {
                        if (event.keyCode == 13) return false;
                      }
                    " @clear="onUpSearch">
                    <i class="el-icon-search" slot="append" @click="onUpSearch">
                    </i>
                  </el-input>
                </el-form>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-card>
			<div class="bull-list" v-loading="loading">
				<el-card :body-style="{ padding: '0px' }" style="width: 1180px;margin: 20px auto;" class="box-card"
					v-for="item in commodityListPage" :key="`${item.id}`">
					<div style="display: flex;">
						<div class="bulkItem-img">
							<img style="width: 100%;height: 100%;" :src="item.filePath" alt="" />
						</div>
						<div class="bulk-cardRight" style="margin-left: 40px;width: 900px;">
							<div style="display: flex;justify-content: space-between;">
								<span class="box-cardTitle">{{ item.name }}</span>
								<span class="box-cardTime">截止日期：{{ item.endTime }}</span>
							</div>
							<div class="bulk-card" style="display: flex;margin-top: 34px;">
								<div class="content">
									<p class="bulk-statis">
										<span class="contemtTit">
											<i class="el-icon-goods"></i>预计重量:
											<span class="bulk-statisNum">{{ item.weight }}</span>
											吨
										</span>
										<span class="contemtTit">
											<i class="el-icon-goods"></i>当前报价:
											<span class="bulk-statisNum">{{ item.price }}</span>
											元
										</span>
									</p>
									<p class="bulk-statis">
										<span class="contemtTit">
											<i class="el-icon-goods"></i>参与公司:
											<span class="bulk-statisNum">{{ item.companyNum }}</span>
											家
										</span>
										<span class="contemtTit">
											<i class="el-icon-goods"></i>最低报价:
											<span class="bulk-statisNum">{{ item.minPrice }}</span>
											元
										</span>
									</p>
								</div>
								<div class="bulk-link">
									<div class="particTenderLink particTender" @click="goParticBidding(item)">
										<div class="tenderLink-icon">
											<img src="/img/homeIcon/tender.png" />
										</div>
										<span>参与投标</span>
									</div>
									<div class="particTenderLink particDetail" @click="clickBullkItem(item)">
										<div class="detailLink-icon">
											<img src="/img/homeIcon/detail.png" />
										</div>
										<span>详细信息</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-card>
			</div>
      <div class="bulk-Pagination">
        <Pagination @changePagination="changePagination" :pageSize="pagination.pageSize" :total="pagination.totalCount"
          :currentPage="pagination.pageIndex" :pageMsg="true" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapState } from "vuex";
import { permissionMixin } from "@/mixin";
export default {
  name: "adressMaintain",
  components: {
    Pagination,
  },
  data() {
    return {
      FindKeyWords: "",
      commodityTypes: "all",
      commodities: "1",
      ListCommodityType: "4",
      form: {
        name: "",
        type: [],
        desc: "",
      },
      queryParam: {},
    };
  },
  mixins: [permissionMixin],
  computed: {
    ...mapState({
      commodityTypesList: (state) => {
        return state.bulkCommodity.commodityTypesList;
      },
      commoditiesList: (state) => {
        return state.bulkCommodity.commoditiesList;
      },
      commodityListPage: (state) => {
        return state.bulkCommodity.commodityListPage;
      },
      pagination: (state) => {
        return state.bulkCommodity.pagination;
      },
      loading: (state) => {
        return state.loading;
      },
    }),
  },
  created() {
    this.fetch();
  },
  methods: {
    handleClick(tab, type, queryParamsKey) {
      if (type === "commodityTypes") {
        let queryParam = {};
        tab.name !== "all" && (queryParam.commodityTypeId = tab.name);
        this.$store.dispatch("bulkCommodity/getCommodities", queryParam);
        delete this.queryParam.CommoditytypeId;
      }
      this.queryParam[queryParamsKey] = tab.name;
      tab.name === "all" && delete this.queryParam[queryParamsKey];
      this.$store.dispatch("bulkCommodity/getCommodityListPage", {
        ...this.page,
        ...this.queryParam,
        PageSize: 10,
        PageIndex: 1,
      });
    },
    onUpSearch() {
      this.CommoditytypeId = "0";
      this.ParentCommoditytypeId = "0";
      this.$store.dispatch("bulkCommodity/getCommodityListPage", {
        PageIndex: 1,
        PageSize: 12,
        FindKeyWords: this.FindKeyWords,
      });
    },
    changePagination(page) {
      this.page = { ...this.page, ...page };
      this.$store.dispatch("bulkCommodity/getCommodityListPage", {
        PageSize: 10,
        PageIndex: 1,
        ...this.page,
        ...this.queryParam,
      });
    },
    fetch() {
      this.$store.dispatch("bulkCommodity/getCommodityTypes").then(() => {
        this.$store.dispatch("bulkCommodity/getCommodityListPage", {
          PageSize: 10,
          PageIndex: 1,
        });
      })
    },
    clickBullkItem(item) {
      this.queryPermisUser(() => {
        this.$router.push({
          path: "/bulkCommodity/details",
          query: {
            id: item.id,
          },
        });
      });
    },
    goParticBidding(item) {
      this.queryPermisUser(() => {
        this.$router.push({
          path: "/bulkCommodity/particBidding",
          query: {
            id: item.id,
          },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  height: 200px;
  background-image: url(/img/pic.png);
  background-size: 100%;
  padding: 49px 0 0 376px;

  .p1 {
    font-size: 36px;
    font-family: YouSheBiaoTi;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    // text-shadow: 0px 2px 11px rgba(4, 23, 117, 0.88);
    background: linear-gradient(0deg, #a2ffce 0%, #ecffff 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // font-style: oblique;
    margin-bottom: 16px;
  }

  .p2 {
    width: 36px;
    height: 4px;
    background: linear-gradient(90deg, #26faff 0%, #2cff99 100%);
    margin-bottom: 29px;
  }

  .p3 {
    width: 726px;
    font-size: 16px;
    font-family: SourceHanSansCN-Light;
    font-weight: 300;
    color: #ffffff;
    line-height: 26px;
  }
}
.bull-list{
	min-height: 300px;
}
.content {
  width: 77%;
  .bulk-statis {
    margin-bottom: 20px;
    overflow: hidden;
    .contemtTit {
      width: 49.5%;
      float: left;
      font-weight: 400;
      color: #666666;
      font-family: SourceHanSansCN-Normal;
      color: #666666;
      i {
        font-size: 18px;
        color: #30af7d;
        margin-right: 11px;
      }
    }
    .bulk-statisNum {
      font-size: 22px;
      color: #f34029;
      font-family: OPPOSans-B;
      padding-left: 14px;
    }
  }
}
.aircont {
  overflow: hidden;
}
.particTenderLink {
  float: left;
  width: 82px;
  cursor: pointer;
  height: 68px;
  text-align: center;
  border-radius: 12px;
  padding-top: 14px;
  > span {
    font-size: 16px;
    font-family: SourceHanSansCN-Normal;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
  }
  &.particTender {
    background: linear-gradient(270deg, #ff934c 0%, #fc686f 100%);
    .tenderLink-icon {
      width: 31px;
      height: 35px;
      display: inline-block;
      img {
        width: 100%;
      }
    }
    span {
      display: block;
    }
  }
  &.particDetail {
    margin-left: 16px;
    background: linear-gradient(90deg, #2975d3 0%, #29ccae 100%);
    .detailLink-icon {
      width: 22px;
      height: 35px;
      display: inline-block;
      img {
        width: 100%;
      }
    }
    span {
      display: block;
    }
  }
}
/deep/.el-tabs__active-bar {
  background-color: white;
}
/deep/.el-tabs__nav-wrap::after {
  background-color: white;
}
.menu {
  font-family: SourceHanSansCN-Light;
  /deep/.el-form-item__label {
    font-weight: 300;
    color: #8e9ca7;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular;
  }
  /deep/.el-tabs__item {
    font-family: SourceHanSansCN-Regular;
    font-weight: 300;
    color: #333333;
    font-size: 14px;
  }

  /deep/.el-tabs__item.is-active {
    font-family: SourceHanSansCN-Bold;
    font-weight: bold;
    color: #30af7d;
  }
  .attribute {
    /deep/.el-input-group__append {
      -webkit-border-top-right-radius: 5px;
      -webkit-border-bottom-right-radius: 5px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-right: 5px;
      border: none;
      i {
        font-weight: bold;
        font-size: 16px;
        margin-right: 8px;
        color: #30af7d;
        cursor: pointer;
      }
    }
  }
}
.bulk-Pagination {
  margin-bottom: 50px;
  padding-top: 10px;
  text-align: center;
}
/deep/.el-tabs__header is-top {
  margin: 0;
}
/deep/.el-form-item {
  margin: 0;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ffffff;
  border: 1px solid #30af7d;
  border-radius: 4px;
  color: #30af7d;
}
/deep/.el-input__icon {
  color: #30af7d;
}
/deep/.el-input__inner {
  background-color: #f4f8fb;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  border: none;
}
.box-card {
  .bulkItem-img {
    width: 300px;
    height: 200px;
  }
  .box-cardTitle {
    font-family: SourceHanSansCN-Medium;
    font-size: 24px;
    font-weight: 500;
    margin-top: 25px;
  }
  .box-cardTime {
    width: 219px;
    background: #eaf5ff;
    height: 30px;
    line-height: 40px;
    padding-bottom: 10px;
    padding-left: 30px;
    background: linear-gradient(180deg, #fff 0%, #eaf5ff 87%);
    border-radius: 20px 0 0 20px;
    font-family: SourceHanSansCN-Normal;
    font-weight: 400;
    color: #879baa;
  }
}
</style>
